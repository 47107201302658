import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useUpdate } from "@refinedev/core";
import { useState } from "react";
import { CouponRule } from "types/coupon";

export default function CouponRuleDistributionDialog(props: {
  open: boolean;
  onClose: () => void;
  couponRule: CouponRule;
  recentlyCreated?: boolean;
}) {
  const { open, onClose, couponRule, recentlyCreated } = props;
  const [pending, setPending] = useState(false);

  const { mutateAsync } = useUpdate();

  const updateRule = async () => {
    setPending(true);
    await mutateAsync({
      resource: "coupons/rules",
      id: couponRule.id,
      values: {},
    });
    setPending(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {couponRule.active ? "Deactivate" : "Activate"} Distribution?
      </DialogTitle>
      <DialogContent>
        <Grid container gap={2}>
          <Grid item>
            {couponRule.active ? (
              <Typography>
                If inactive, {couponRule.coupon.name} Coupons will not be
                airdropped to {couponRule.membershipType.name} Membership owners
                anymore.
              </Typography>
            ) : (
              <Typography>
                If active, {couponRule.coupon.name} Coupons will be airdropped
                to all {couponRule.membershipType.name} Membership owners that
                haven't received it through this rule yet.
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              You can activate / deactivate this anytime later.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {recentlyCreated ? (
          <Button variant="outlined" onClick={onClose}>
            Activate Later
          </Button>
        ) : (
          <Button onClick={onClose}>Cancel</Button>
        )}
        <LoadingButton
          variant="contained"
          loading={pending}
          onClick={updateRule}
          color={couponRule.active ? "warning" : "success"}
        >
          {couponRule.active ? "Deactivate" : "Activate"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
