import { useFormContext } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import FormTextField from "../../components/form/FormTextField";
import { ofNews } from "../../types/news";
import React from "react";
import FormDateField from "../../components/form/FormDateField";
import FormEditorField from "../../components/form/FormEditorField";
import FormImageField from "../../components/form/FormImageField";

export function NewsForm() {
  const { control } = useFormContext();

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Grid container spacing={4} marginTop={1}>
        <Grid item xs={12} md={8} xl={4}>
          <FormTextField
            name={ofNews("title")}
            control={control}
            fullWidth
            label="Title"
            required
          />
          <FormDateField
            name={ofNews("publishAt")}
            control={control}
            label="Publish At"
            placeholder="Select date at which this news should be published"
            required
            additionalRules={{
              required: { value: true, message: "Must exist" },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "flex", placeContent: "center" }}
        >
          <FormImageField
            control={control}
            name={ofNews("image")}
            required
            squareImages
          />
        </Grid>
        <Grid item xs={12} xl={8}>
          <FormEditorField
            name={ofNews("content")}
            control={control}
            fullWidth
            label="Content"
            placeholder="Enter the content of the news"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
