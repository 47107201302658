import { GridColDef } from "@mui/x-data-grid";
import { PublicKeyLink } from "components/PublicKeyLink";
import EditMenuItem from "components/table/EditMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import { MembershipType, ofMembershipType } from "../types/membershipType";
import { renderCoinAmount } from "../utils/coinUtils";
import { Coin } from "../types/coin";
import { ImageColumn } from "../components/table/ImageColumn";

export const membershipTypeCols = (props: {
  coin?: Coin | null;
}): GridColDef<MembershipType>[] => {
  const optionalCoinCol: GridColDef<MembershipType>[] = props.coin
    ? [
        {
          field: ofMembershipType("includedCoinAmount"),
          headerName: "Included Coin",
          type: "number",
          flex: 1,
          filterable: false,
          renderCell: ({ row }) =>
            renderCoinAmount(row.includedCoinAmount, props.coin!),
        },
      ]
    : [];
  return [
    {
      field: ofMembershipType("id"),
      headerName: "Id",
    },
    {
      field: ofMembershipType("image"),
      headerName: "Image",
      renderCell: function render({ row }) {
        return <ImageColumn image={row.image} />;
      },
      minWidth: 120,
      sortable: false,
      flex: 1,
    },
    {
      field: ofMembershipType("name"),
      headerName: "Name",
      flex: 1,
    },
    {
      field: ofMembershipType("symbol"),
      headerName: "Symbol",
      flex: 1,
    },
    ...optionalCoinCol,
    {
      field: ofMembershipType("mintCount"),
      headerName: "Mint Count",
      type: "number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: ofMembershipType("membershipKey"),
      headerName: "Mint Key",
      renderCell: function render({ row }) {
        return <PublicKeyLink pubKey={row.membershipKey} type="address" />;
      },
      flex: 1,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "menu",
      headerName: "",
      renderCell: function render({ row }) {
        return (
          <MoreActionsButton>
            <EditMenuItem resource={"membership-types"} id={row.id} />
          </MoreActionsButton>
        );
      },
      align: "center",
      maxWidth: 80,
      filterable: false,
      sortable: false,
      disableExport: true,
      disableColumnMenu: true,
    },
  ];
};
