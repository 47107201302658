import { Grid, useTheme } from "@mui/material";
import { useBranch } from "../provider/BranchProvider";

export default function CurrencyAmount(props: {
  /** Token amount, accounted for decimals.
   * This is often provided by web3js library. */
  uiAmount?: number | null;
  /** Token amount, in cents.
   * This is often provided by backend. */
  amountOfCents?: number | null;
}) {
  const branch = useBranch();
  const theme = useTheme();

  const amount: null | number =
    props.uiAmount ||
    (props.amountOfCents && props.amountOfCents / 100) ||
    null;
  return (
    <Grid display="flex" alignItems="center">
      {branch?.coin?.image && (
        <img
          src={branch.coin.image.url}
          alt="Coin"
          style={{
            width: theme.spacing(3),
            marginRight: theme.spacing(1),
          }}
        />
      )}
      <span style={{ alignSelf: "center" }}>
        {amount ? amount.toLocaleString("en-US") : "?"}
      </span>
    </Grid>
  );
}
