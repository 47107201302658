import { TransactionStatus } from "./transaction";
import { Membership } from "./membership";

export interface Airdrop {
  id: string;
  membership: Membership;
  status: TransactionStatus;
  createdAt: string;
  amount: number;
}

export interface CreateAirdrop {
  couponId: string | null;
  receiverWalletKey: string | null;
  amount: number;
}

export const defaultCreateAidrop: CreateAirdrop = {
  couponId: null,
  receiverWalletKey: null,
  amount: 1,
};
