import { Box } from "@mui/material";
import { HttpError } from "@refinedev/core";
import { Edit, SaveButton } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import { FormProvider } from "react-hook-form";
import {
  defaultMembershipType,
  MembershipType,
  ofMembershipType,
} from "../../types/membershipType";

export default function EditMembershipType() {
  const methods = useForm<MembershipType, HttpError>({
    defaultValues: defaultMembershipType,
  });
  const {
    control,
    handleSubmit,
    refineCore: { onFinish },
  } = methods;

  const handleSave = async (e: React.BaseSyntheticEvent) => {
    await handleSubmit((values) => {
      onFinish({
        ...values,
      });
    })(e);
  };

  return (
    <Edit
      footerButtons={() => (
        <SaveButton onClick={(e: React.BaseSyntheticEvent) => handleSave(e)} />
      )}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormTextField
            name={ofMembershipType("includedCoinAmount")}
            control={control}
            type="number"
            fullWidth
            label="Included Coin amount"
            InputLabelProps={{ shrink: true }}
            required
            helperText={
              "The amount of coin a member will receive with this membership."
            }
            transform={{
              input: (v: number) => v * 100,
              output: (v: number) => v / 100,
            }}
          />
        </Box>
      </FormProvider>
    </Edit>
  );
}
