import { GridColDef } from "@mui/x-data-grid";
import { Airdrop } from "types/airdrop";
import { MemberLink } from "../pages/members/MemberLink";

export function airdropCols(branchId: number): GridColDef<Airdrop>[] {
  return [
    {
      field: "id",
      headerName: "Id",
    },
    {
      field: "walletAddress",
      headerName: "Wallet",
      flex: 1,
      renderCell: function render({ row }) {
        return (
          <MemberLink memberId={row.membership.memberId} branchId={branchId}>
            {" "}
            {row.membership.walletAddress}{" "}
          </MemberLink>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
      renderCell: function render({ row }) {
        return <span>{new Date(row.createdAt).toLocaleString()}</span>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      type: "number",
    },
  ];
}
