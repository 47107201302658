import { DataGrid } from "@mui/x-data-grid";
import { List, useDataGrid } from "@refinedev/mui";
import { useBranch } from "../../provider/BranchProvider";
import { Membership } from "../../types/membership";
import { membershipCols } from "../../tables/membershipCols";

export default function MembershipList() {
  const branch = useBranch();

  const { dataGridProps } = useDataGrid<Membership>({
    filters: {
      permanent: [{ field: "branchId", operator: "eq", value: branch?.id }],
    },
  });

  return (
    <List>
      <DataGrid {...dataGridProps} columns={membershipCols} autoHeight />
    </List>
  );
}
