import { List } from "@refinedev/mui";
import { DataGrid } from "@mui/x-data-grid";
import { useBranchDependentDataGrid } from "../../utils/hooks/useBranchDependentDataGrid";
import { Member } from "../../types/member";
import { memberCols } from "../../tables/memberCols";

export default function MemberList() {
  const { dataGridProps } = useBranchDependentDataGrid<Member>({});

  return (
    <List>
      <DataGrid {...dataGridProps} columns={memberCols} autoHeight />
    </List>
  );
}
