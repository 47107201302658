import { MembershipType } from "./membershipType";
import { ImageDto } from "./image";
import { propertiesOf } from "../utils/propertyOf";

export interface Coupon {
  id: string;
  name: string;
  description?: string;
  image: ImageDto | null;
  validUntil?: string;
  externalUrl?: string;
}

export interface MemberCoupon {
  id: string;
  image: ImageDto | null;
  name: string;
  couponStatus: string;
}

export const defaultCoupon: Coupon = {
  id: "",
  name: "",
  externalUrl: "",
  description: "",
  image: null,
  validUntil: "",
};

export interface CouponRule {
  id: string;
  membershipType: MembershipType;
  coupon: Coupon;
  createdAt: string;
  active: boolean;
  airdropCount: number;
}

export interface CreateCouponRule {
  membershipTypeId: number | null;
  couponId: string | null;
}

export const defaultCouponRule: CreateCouponRule = {
  membershipTypeId: null,
  couponId: null,
};

export const ofCoupon = propertiesOf<Coupon>();
