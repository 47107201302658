import { useForm } from "@refinedev/react-hook-form";
import { HttpError } from "@refinedev/core";
import { useBranch } from "../../provider/BranchProvider";
import { FieldValues, FormProvider } from "react-hook-form";
import { Create } from "@refinedev/mui";
import React from "react";
import { CreateMembershipForm } from "./CreateMembershipForm";
import {
  CreateMembershipDto,
  defaultCreateMembership,
} from "../../types/membership";

export function CreateMembership(props: {}) {
  const methods = useForm<CreateMembershipDto, HttpError>({
    defaultValues: defaultCreateMembership,
  });
  const branch = useBranch();
  const customHandleSubmit = (values: FieldValues) => {
    return methods.refineCore.onFinish({ ...values, branchId: branch?.id });
  };
  return (
    <Create
      saveButtonProps={{
        ...methods.saveButtonProps,
        onClick: methods.handleSubmit(customHandleSubmit),
      }}
    >
      <FormProvider {...methods}>
        <CreateMembershipForm />
      </FormProvider>
    </Create>
  );
}
