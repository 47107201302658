import { useFormContext } from "react-hook-form";
import { Box, FormControl, InputLabel } from "@mui/material";
import FormTextField from "../../components/form/FormTextField";
import { ofCreateMembership } from "../../types/membership";
import React from "react";
import SelectMembership from "../../components/form/SelectMembership";
import { WalletAddressField } from "../../components/form/WalletAddressField";
import { useBranch } from "provider/BranchProvider";

export function CreateMembershipForm(props: {}) {
  const { control } = useFormContext();
  const branch = useBranch();

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <FormControl variant="standard" required>
        <InputLabel>Select Membership Type</InputLabel>
        <SelectMembership name="membershipTypeId" control={control} />
      </FormControl>
      <WalletAddressField
        name={ofCreateMembership("walletAddress")}
        control={control}
        fullWidth
        label="Wallet Address"
        required
      />
      {branch?.personalDataRequired && (
        <>
          <FormTextField
            name={ofCreateMembership("name")}
            control={control}
            fullWidth
            label="Name"
            placeholder="Name of member"
          />
          <FormTextField
            name={ofCreateMembership("email")}
            control={control}
            label={"Email"}
            additionalRules={{
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "Invalid Email",
              },
            }}
          />
          <FormTextField
            name={ofCreateMembership("company")}
            control={control}
            fullWidth
            label="Company"
            placeholder="Company of member"
          />
        </>
      )}
      <FormTextField
        name={ofCreateMembership("serial")}
        control={control}
        fullWidth
        label="Serial"
        placeholder="Leave empty to select the next available serial"
      />
    </Box>
  );
}
