import { Box } from "@mui/material";
import { ImageDto } from "../../types/image";

export function ImageColumn(props: { image: ImageDto | undefined | null }) {
  if (!props.image) return <></>;
  return (
    <Box
      component="img"
      src={props.image?.url}
      sx={(theme) => ({
        verticalAlign: "middle",
        maxWidth: theme.spacing(4),
      })}
    />
  );
}
