import { Grid } from "@mui/material";
import { useParsed } from "@refinedev/core";
import { useDataGrid } from "@refinedev/mui";
import React from "react";
import { Airdrop } from "types/airdrop";
import CreateAirdropDialog from "./CreateAirdropDialog";
import { DataGrid } from "@mui/x-data-grid";
import { airdropCols } from "tables/airdropCols";
import { useBranch } from "../../../provider/BranchProvider";

export default function AirdropList() {
  const { id: couponId } = useParsed();
  const branch = useBranch();
  const { dataGridProps } = useDataGrid<Airdrop>({
    resource: "airdrops/" + couponId,
    queryOptions: { refetchOnMount: true, refetchOnWindowFocus: true },
  });

  return (
    <Grid container direction={"column"} gap={3}>
      <Grid item justifyContent={"flex-end"} display={"flex"}>
        <CreateAirdropDialog />
      </Grid>
      <Grid item>
        <DataGrid
          {...dataGridProps}
          columns={airdropCols(branch!!.id)}
          autoHeight
          sx={{
            ...dataGridProps.sx,
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
