import { GridColDef } from "@mui/x-data-grid";
import { ofMember } from "../types/member";
import { Membership } from "../types/membership";
import React from "react";
import { MembershipChip } from "../pages/memberships/MembershipChip";
import { Box } from "@mui/material"; // Ensure this path is correct

export const memberCols: GridColDef[] = [
  {
    field: ofMember("walletAddress"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofMember("memberships"),
    headerName: "Memberships",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
        >
          {params.value.map((membership: Membership) => (
            <MembershipChip key={membership.id} membership={membership} />
          ))}
        </Box>
      );
    },
  },
];
