import { Alert, Box, Grid } from "@mui/material";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormImageField from "components/form/FormImageField";
import FormTextField from "components/form/FormTextField";
import { FieldValues, FormProvider } from "react-hook-form";
import {
  defaultMembershipType,
  MembershipType,
  ofMembershipType,
} from "../../types/membershipType";
import { useBranch } from "../../provider/BranchProvider";
import { HttpError } from "@refinedev/core";
import React from "react";

export default function CreateMembershipType() {
  const methods = useForm<MembershipType, HttpError>({
    defaultValues: defaultMembershipType,
  });
  const branch = useBranch();

  const {
    saveButtonProps,
    control,
    refineCore: { onFinish },
    handleSubmit,
  } = methods;

  const customHandleSubmit = (values: FieldValues) => {
    return onFinish({ ...values, branchId: branch?.id });
  };

  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(customHandleSubmit),
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormTextField
            name={ofMembershipType("name")}
            control={control}
            label={"Name"}
            required
            additionalRules={{
              maxLength: {
                value: 32,
                message: "Name must be at most 32 characters long",
              },
            }}
          />
          <FormTextField
            name={ofMembershipType("symbol")}
            control={control}
            label={"Symbol"}
            required
            additionalRules={{
              maxLength: {
                value: 10,
                message: "Symbol must be at most 10 characters long",
              },
            }}
          />
          {branch?.coin && (
            <FormTextField
              name={ofMembershipType("includedCoinAmount")}
              control={control}
              type="number"
              fullWidth
              label="Included Coin amount"
              InputLabelProps={{ shrink: true }}
              required
              helperText={
                "The amount of tokens a member will receive with this membership."
              }
              transform={{
                input: (v: number) => v * 100,
                output: (v: number) => v / 100,
              }}
            />
          )}
          <Grid container spacing={4} marginTop={1} direction={"column"}>
            <Grid item>
              <FormImageField
                control={control}
                name={ofMembershipType("image")}
                required
                squareImages
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} marginTop={1} direction={"column"}>
            <Grid item>
              <Alert severity="warning">
                Please note that the membership fields cannot be changed after
                creation.
              </Alert>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Create>
  );
}
