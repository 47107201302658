import { GridColDef } from "@mui/x-data-grid";
import UpdateCouponRuleDistirbutionDialog from "pages/coupons/rules/UpdateCouponRuleDistirbutionDialog";
import { CouponRule } from "types/coupon";
import { MembershipTypeLink } from "../pages/membership-types/MembershipTypeLink";

export const couponRuleCols: GridColDef<CouponRule>[] = [
  {
    field: "id",
    headerName: "Id",
  },
  {
    field: "membershipType",
    headerName: "Membership Type",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <MembershipTypeLink
          membershipTypeId={row.membershipType.id}
          branchId={row.membershipType.branchId}
        >
          {row.membershipType.name}
        </MembershipTypeLink>
      );
    },
  },
  {
    field: "airdropCount",
    headerName: "Airdrop count",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "createdAt",
    headerName: "Created At",
    renderCell: function render({ row }) {
      return new Date(row.createdAt).toLocaleString();
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: "active",
    headerName: "Distribution",
    renderCell: function render({ row }) {
      return <UpdateCouponRuleDistirbutionDialog couponRule={row} />;
    },
    align: "center",
  },
];
