import { EntityRef } from "utils/rest/entities";
import { propertiesOf } from "../utils/propertyOf";
import { ImageDto } from "./image";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.MembershipDto] */
export interface Membership extends EntityRef {
  id: number;
  address: string;
  name: string;
  symbol: string;
  serial: number;
  image: ImageDto | null;
  membershipTypeId: number;
  walletAddress: string;
  memberId: number;
  description?: string;
  externalUrl?: string;
}

export interface CreateMembershipDto {
  membershipTypeId: number | null;
  walletAddress: string;
  serial: number | null;
  name?: string;
  email?: string;
  company?: string;
  description?: string;
  externalUrl?: string;
}

export const defaultCreateMembership: CreateMembershipDto = {
  membershipTypeId: null,
  walletAddress: "",
  serial: null,
};

export const ofMembership = propertiesOf<Membership>();
export const ofCreateMembership = propertiesOf<CreateMembershipDto>();
