import { Image } from "./event";

export interface Ticket {
  id: string;
  name: string;
  description: string;
  image: { id: string; url: string } | null;
  supply: number | null;
  price: number | null;
  salesStart: string | null;
}

export interface MemberTicket {
  id: string;
  eventId: string;
  name: string;
  image: Image | null;
  price: number;
  createdAt: string;
}

export const defaultEventTicket: Ticket = {
  id: "",
  name: "",
  description: "",
  image: null,
  supply: null,
  price: 0,
  salesStart: "",
};
