import { ImageDto } from "./image";
import { propertiesOf } from "../utils/propertyOf";

/** Mirrors kd-mem-server's [com.kurzdigital.kd_newss.server.dto.admin_api.AdminNewsDto] */
export interface News {
  branchId: number;
  publishAt: string;
  title: string;
  content: string;
  image?: ImageDto;
}

export const ofNews = propertiesOf<News>();

export const defaultNews: News = {
  branchId: 0,
  publishAt: "",
  title: "",
  content: "",
};
