import { GridColDef } from "@mui/x-data-grid";
import CurrencyAmount from "components/CurrencyAmount";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import EditTicketDialog from "pages/events/form/EditTicketDialog";
import { Ticket } from "types/ticket";

export const ticketCols = (
  updateForm: (ticket: Ticket, index: number) => void
): GridColDef<Ticket & { index: number }>[] => [
  {
    field: "id",
    headerName: "Id",
    flex: 1,
  },
  {
    field: "image",
    headerName: "Image",
    flex: 1,
    renderCell: function render({ row }) {
      if (!row.image) {
        return null;
      }
      return <img src={row.image?.url} alt={"Ticket"} height={"100%"} />;
    },
    minWidth: 120,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "supply",
    headerName: "Available quantity",
    flex: 1,
    renderCell: function render({ row }) {
      return row.supply || "-";
    },
  },
  {
    field: "price",
    headerName: "Price",
    flex: 1,
    filterable: false,
    renderCell: function render({ row }) {
      return row.price ? <CurrencyAmount amountOfCents={row.price} /> : "-";
    },
  },
  {
    field: "salesStart",
    headerName: "Start of sale",
    flex: 1,
    renderCell: function render({ row }) {
      return row.salesStart ? (
        <span>{new Date(row.salesStart).toLocaleString()}</span>
      ) : (
        "-"
      );
    },
  },
  {
    field: "more",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <EditTicketDialog
            ticketId={row.id}
            updateForm={(ticket) => updateForm(ticket, row.index)}
          />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
