import { EntityRef } from "utils/rest/entities";
import { propertiesOf } from "../utils/propertyOf";
import { ImageDto } from "./image";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.MembershipTypeDto] */
export interface MembershipType extends EntityRef {
  id: number;
  name: string;
  symbol: string;
  branchId: number;
  membershipKey: string;
  image: ImageDto | null;
  includedCoinAmount: number;
  mintCount: number;
}

export const defaultMembershipType: MembershipType = {
  id: 0,
  name: "",
  symbol: "",
  branchId: 0,
  membershipKey: "",
  image: null,
  includedCoinAmount: 0,
  mintCount: 0,
};

export enum MembershipLevel {
  SILVER = "SILVER",
  GOLD = "GOLD",
  ULTRA = "ULTRA",
  OBSIDIAN = "OBSIDIAN",
}

export const ofMembershipType = propertiesOf<MembershipType>();
