import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { HttpError } from "@refinedev/core";
import { defaultMember, Member, ofMember } from "../../types/member";
import { FormProvider } from "react-hook-form";
import React from "react";
import FormTextField from "../../components/form/FormTextField";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { membershipCols } from "../../tables/membershipCols";
import { WalletAddressField } from "../../components/form/WalletAddressField";

export function EditMember() {
  const methods = useForm<Member, HttpError>({
    defaultValues: defaultMember,
  });

  const memberships = methods.watch(ofMember("memberships"));

  return (
    <Edit>
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormTextField
            name={ofMember("email")}
            control={methods.control}
            label={"Email"}
            additionalRules={{
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "Invalid Email",
              },
            }}
            disabled={true}
          />
          <FormTextField
            name={ofMember("name")}
            control={methods.control}
            label={"Name"}
            disabled={true}
          />
          <FormTextField
            name={ofMember("company")}
            control={methods.control}
            label={"Company"}
            disabled={true}
          />
          <WalletAddressField
            name={ofMember("walletAddress")}
            control={methods.control}
            disabled={true}
          />
        </Box>
        <Box display={"flex"} flexDirection={"column"} marginTop={4}>
          <Typography variant={"h6"}>Memberships</Typography>
          {memberships && (
            <DataGrid columns={membershipCols} autoHeight rows={memberships} />
          )}
        </Box>
      </FormProvider>
    </Edit>
  );
}
